














































import { Component, Prop, Vue } from "vue-property-decorator";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxProgressBar } from "devextreme-vue/progress-bar";
import { ssmTokenService } from "@/shared/services/token-service";

@Component({
  components: { DxFileUploader, DxProgressBar },
})
export default class DocumentsUpload extends Vue {
  @Prop({ default: "" }) url_upload!: string;
  @Prop({ default: "Documentos" }) title!: string;
  public isDropZoneActive = false;
  public imageSource: any = null;
  public textVisible = true;
  public progressVisible = false;
  public progressValue = 0;
  public allowedFileExtensions = [
    ".zip",
    ".rar",
    ".json",
    ".pdf",
    ".mp4",
    ".mkv",
    ".avi",
    ".wmv",
    ".mov",
    ".gif",
    ".xlsx",
    ".xls",
    ".csv",
    ".doc",
    ".docx",
    ".txt",
    ".rfd",
    ".jpeg",
    ".jpg",
    ".png",
  ];

  public onDropZoneEnter(e: any) {
    if (e.dropZoneElement.id === "dropzone-external") {
      this.isDropZoneActive = true;
    }
  }

  public onDropZoneLeave(e: any) {
    if (e.dropZoneElement.id === "dropzone-external") {
      this.isDropZoneActive = false;
    }
  }

  public onUploaded(e: any) {
    const file = e.file;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.isDropZoneActive = false;
      this.imageSource = fileReader.result;
    };
    fileReader.readAsDataURL(file);
    this.textVisible = false;
    this.progressVisible = false;
    this.progressValue = 0;
    this.$emit("update");
  }

  public onProgress(e: any) {
    this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
  }

  public onUploadStarted() {
    this.imageSource = "";
    this.progressVisible = true;
  }

  public headers_upload(): { Authorization: string } {
    return { Authorization: "Bearer " + ssmTokenService.getToken() };
  }
}
